<template>
  <div class="shipping-restriction">
    <p>Are you ordering from outside the European Union or associated countries? Regulations may prevent us from
      shipping food items to you. We know it won't work for USA and UK. Please ask us and we'll find out. We would love
      to share at least Iberian Ham with you.</p>
  </div>
</template>

<style scoped>
.shipping-restriction {
  margin: 0.5em;
  border: thin black solid;
  font-size: smaller;
  padding: 0.2em 1em 0.2em 1em;
  background-color: rgb(253, 204, 204);
}
</style>
